import {
  ConvertedEventsParticipants,
  EventObj,
  EventParticipantsFormatted,
} from 'ck-queries';

export type EventTableStructure = {
  title:
    | 'TITEL'
    | 'STARTDATUM'
    | 'ORGANISATÖR'
    | 'SKAPAD AV'
    | 'KASSANR'
    | 'KOMMITTÉ'
    | 'BOKNINGAR'
    | 'PINKOD'
    | '';
  dataPath: keyof EventObj | 'committee' | keyof ConvertedEventsParticipants;
};

export const tableStructure: EventTableStructure[] = [
  { title: 'TITEL', dataPath: 'nameSwedish' },
  { title: 'STARTDATUM', dataPath: 'startTime' },
  { title: 'SKAPAD AV', dataPath: 'createdBy' },
];

export const participantsTableStructure: EventTableStructure[] = [
  { title: 'TITEL', dataPath: 'title' },
  { title: 'STARTDATUM', dataPath: 'startDate' },
  { title: 'ORGANISATÖR', dataPath: 'host' },
  { title: 'BOKNINGAR', dataPath: 'participantsQuota' },
  { title: 'PINKOD', dataPath: 'pin' },
];

export type EventCheckingsTableStructure = {
  title:
    | 'FÖRNAMN'
    | 'EFTERNAMN'
    | 'CID/MAIL'
    | `INCHECKNINGAR (${number}/${number})`;
  dataPath: keyof EventParticipantsFormatted;
};

export const checkInsTableStructure = (
  checkedIn: number,
  total: number
): EventCheckingsTableStructure[] => [
  { title: 'FÖRNAMN', dataPath: 'firstName' },
  { title: 'EFTERNAMN', dataPath: 'lastName' },
  { title: 'CID/MAIL', dataPath: 'cidOrEmail' },
  { title: `INCHECKNINGAR (${checkedIn}/${total})`, dataPath: 'status' },
];
