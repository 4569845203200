import { Form, LoadingSpinner, PasswordInput } from 'ck-components';
import {
  PutUpdatePasswordV1,
  usePasswordUpdate,
  useValidateResetPasswordToken,
} from 'ck-queries';
import { SubmitHandler, useForm } from 'react-hook-form';

import { TypedNavigate } from 'ck-utils';
import { chalmersLogo } from 'ck-assets';
import toast from 'react-hot-toast';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

interface UpdatePasswordFormValues extends PutUpdatePasswordV1 {
  confirmPassword: string;
}

/**
 * ResetPasswordPage is the page where the user can reset their password
 * after receiving a reset password email.
 */
export function ResetPasswordPage() {
  const form = useForm<UpdatePasswordFormValues>({ mode: 'onBlur' });
  const { handleSubmit, watch } = form;
  const newPassword = watch('newPassword');

  // Token provided in the url
  const { token } = useParams();

  const M_validateToken = useValidateResetPasswordToken();
  const M_updatePassword = usePasswordUpdate();

  // Before rendering the page, we need to validate the token against the backend
  useEffect(() => {
    M_validateToken.mutate({ token });
  }, [token]);

  if (M_validateToken.isLoading) {
    return (
      <div className='flex h-screen w-screen items-center justify-center'>
        <LoadingSpinner width={100} />
      </div>
    );
  }

  // Tried to validate token but mutation failed
  // Toast sent by general error handler
  if (M_validateToken.isError) {
    return <TypedNavigate to={'/login'} />;
  }

  // Nullcheck for token in params
  if (!token) {
    toast.error('Länken är ogiltig');
    return <TypedNavigate to={'/login'} />;
  }

  const onSubmit: SubmitHandler<UpdatePasswordFormValues> = ({
    newPassword,
  }) => {
    M_updatePassword.mutate({ token, newPassword });
  };

  return (
    <div className='flex h-screen w-full flex-col items-center justify-center'>
      <img
        src={chalmersLogo}
        alt='Chalmers Student Union Logo'
        className='mb-10 h-36 w-96'
      />
      <Form
        onSubmit={handleSubmit(onSubmit)}
        submitButtonLabel={'Uppdatera lösenord'}
        className='max-w-[400px]'
        form={form}
        isSubmitting={M_updatePassword.isLoading}
      >
        <PasswordInput
          placeholder='Nytt lösenord'
          label='Nytt lösenord'
          name='newPassword'
          parentForm={form}
          minLength={6}
        />

        <PasswordInput
          label='Bekräfta lösenord'
          placeholder='Bekräfta lösenord'
          name='confirmPassword'
          parentForm={form}
          minLength={6}
          options={{
            validate: (value) =>
              value === newPassword || 'Lösenorden måste vara samma',
          }}
        />
      </Form>
    </div>
  );
}
