import { Copy, Download, Edit, Trash } from 'ck-assets';

import { OurTooltip } from 'ck-components';
import { ReactSVG } from 'react-svg';
import clsx from 'clsx';

export interface RowComponentActionProps {
  onRowClick?: (data: any) => void;
  onDeleteClick?: (data: any) => void;
  onEditClick?: (data: any) => void;
  onCopyClick?: (data: any) => void;
  conditionDisableEditClick?: (data: any) => boolean;
  conditionDisableCopyClick?: (data: any) => boolean;
  onDownloadClick?: (data: any) => void;
  onCustomClick?: (data: any) => void;
  customLastColumn?: React.ReactNode;
}

interface RowComponentProps<T> extends RowComponentActionProps {
  dataPaths: Array<keyof T>;
  data: { [key in keyof T]: any };
}

/**
 * The row component which is rendered within the table.
 *
 * @param dataPaths an array of strings containing the paths which the data will be mapped against
 * @param data the data which will be rendered on the current row
 * @param onRowClick a callback executed whenever the current row is Clicked
 * @param conditionDisableCopyClick condition to hide copy-button depending on row data
 * @param conditionDisableEditClick condition to hide edit-button depending on row data
 */

export default function RowComponent<T extends { [key: string]: any }>({
  dataPaths,
  data,
  onRowClick,
  onDeleteClick,
  onEditClick,
  onCopyClick,
  conditionDisableEditClick,
  conditionDisableCopyClick,
  onCustomClick,
  onDownloadClick,
  customLastColumn,
}: RowComponentProps<T>) {
  const hasFunctions = onDeleteClick || onEditClick || onCopyClick;

  const enableEdit =
    !conditionDisableEditClick || !conditionDisableEditClick(data);

  const enableCopy =
    !conditionDisableCopyClick || !conditionDisableCopyClick(data);

  return (
    <tr className='w-full'>
      {dataPaths.map((path, index) => (
        <td
          key={`${data[path]}-${index}`}
          className={clsx(
            'h-16 overflow-hidden text-ellipsis whitespace-nowrap border-t border-b bg-white px-4 py-4 font-light first:rounded-l-2xl first:border-l first:pl-7 last:rounded-r-2xl last:border-r last:pr-7',
            onRowClick && 'hover:cursor-pointer'
          )}
          onClick={() => onRowClick && onRowClick(data)}
        >
          <OurTooltip content={data[path]} id={data[path]}>
            {data[path]}
          </OurTooltip>
        </td>
      ))}
      {hasFunctions && (
        <td
          key={'functions'}
          className={
            ' h-16 gap-2 place-self-end overflow-hidden text-ellipsis whitespace-nowrap border-t border-b bg-white px-4 py-4 font-light last:rounded-r-2xl last:border-r last:pr-7'
          }
        >
          <div className='flex flex-row items-center justify-end '>
            {enableCopy && onCopyClick && (
              <OurTooltip content='kopiera' id='copy-row'>
                <button
                  aria-label={'copyRowButton'}
                  onClick={() => onCopyClick(data)}
                >
                  <ReactSVG src={Copy} className='w-5 cursor-pointer' />
                </button>
              </OurTooltip>
            )}
            {enableEdit && onEditClick && (
              <OurTooltip content='redigera' id='edit-row'>
                <button
                  aria-label={'editRowButton'}
                  onClick={() => onEditClick(data)}
                >
                  <ReactSVG src={Edit} className='w-5 cursor-pointer' />
                </button>
              </OurTooltip>
            )}
            {onDownloadClick && (
              <OurTooltip content='ladda ner' id='download-row'>
                <button
                  aria-label={'downloadRowButton'}
                  onClick={() => onDownloadClick(data)}
                >
                  <ReactSVG
                    title='Download button'
                    src={Download}
                    className='w-5 cursor-pointer'
                  />
                </button>
              </OurTooltip>
            )}
            {onDeleteClick && (
              <OurTooltip content='radera' id='delete-row'>
                <button
                  aria-label={'deleteRowButton'}
                  onClick={() => onDeleteClick(data)}
                >
                  <ReactSVG
                    title='Delete button'
                    src={Trash}
                    className='w-5 cursor-pointer'
                  />
                </button>
              </OurTooltip>
            )}
          </div>
        </td>
      )}
      {customLastColumn && (
        <td
          onClick={onCustomClick ? () => onCustomClick(data) : undefined}
          className={
            'flex h-16 flex-row items-center justify-end gap-2 place-self-end text-ellipsis whitespace-nowrap border-t border-b bg-white px-4 py-4 font-light first:border-l last:rounded-r-2xl last:border-r last:pr-7'
          }
        >
          {customLastColumn}
        </td>
      )}
    </tr>
  );
}
