import { ConfirmationModal, ConfirmationModalProps } from 'ck-components';

import { toast } from 'react-hot-toast';
import { useDeleteCommittee } from 'ck-queries';

interface Props extends Omit<ConfirmationModalProps, 'children'> {
  committeeName: string;
  committeeId: string;
  onClose?: () => void;
}

export const RemoveCommitteeModal = (props: Props) => {
  const { committeeId, committeeName, onClose } = props;

  const M_deleteCommittee = useDeleteCommittee();

  const onConfirmation = () => {
    toast
      .promise(M_deleteCommittee.mutateAsync(committeeId), {
        loading: 'Laddar...',
        success: `Kommittén ${committeeName} har tagits bort`,
        error: 'Kunde inte ta bort kommittén',
      })
      .then((response) => {
        response.ok && props.setIsOpen(false);
        if (onClose) {
          onClose();
        }
      });

    M_deleteCommittee.mutateAsync(committeeId);
  };

  return (
    <ConfirmationModal
      {...props}
      confirmationLabel='Ta bort'
      onConfirmation={onConfirmation}
      isDelete
      customStyle='w-[480px]'
      isLoading={M_deleteCommittee.isLoading}
    >
      <p>
        Raderar du <span className='font-bold'>{committeeName}</span> försvinner
        alla tillhörande event och data. Vill du ta bort kommittén?
      </p>
    </ConfirmationModal>
  );
};
