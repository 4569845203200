import { CancelButton, ConfirmButton } from 'ck-components';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import {
  LocalStorageTypes,
  localStorageTyped,
  saveInLocalStorage,
} from 'ck-utils';
import { PropsWithChildren, useEffect } from 'react';

import clsx from 'clsx';

interface FormProps<T extends FieldValues> extends PropsWithChildren {
  onSubmit: () => void;
  submitButtonLabel: string;
  className?: string;
  submitButtonStyle?: string;
  buttonType?: 'button' | 'submit';
  centerButtons?: boolean;
  isSubmitting?: boolean;
  showCancelButton?: boolean;
  onCancel?: () => void;
  cancelButtonStyle?: string;
  cancelButtonLabel?: string;
  formLocalStorageKey?: keyof LocalStorageTypes;
  form: UseFormReturn<T, any>;
  onCleanForm?: () => void;
  onSaveDraft?: () => void;
  ignoreGap?: boolean;
}

/**
 * The root object of our form wrapper with custom styling. Only enables the submit button if the
 * form in its entirety is valid.
 *
 * @param children the child objects to be rendered (FormLabels and FormInputs)
 * @param onSubmit the submit function which should be wrapped in the handleSubmit from react-hook-form
 * @param submitButtonLabel the text for the submit button
 * @param className optional styling
 * @param submitButtonStyle optional styling for the submit button
 * @param onCancel callback function for the cancel button
 * @param cancelButtonStyle optional styling for the cancel button
 * @param cancelButtonLabel the text for the cancel button
 * @param buttonType set to button to prevent default onSubmit behavior like a page refresh
 * @param centerButtons set to true to center the buttons
 * @param isSubmitting set to true to show a loading spinner on the submit button
 * @param ignoreGap optional prop to ignore the gap, used in modals
 * @param form the form
 * @param formLocalStorageKey optional locals torage key value, to cache form entries on change
 */

export const Form = <T extends FieldValues>({
  children,
  onSubmit,
  onCancel,
  className,
  submitButtonLabel,
  submitButtonStyle = 'w-[200px] px-4 h-[60px]',
  cancelButtonStyle = 'w-[200px] px-4 h-[60px]',
  cancelButtonLabel = 'Avbryt',
  buttonType = 'submit',
  centerButtons,
  isSubmitting,
  ignoreGap = false,
  onCleanForm,
  onSaveDraft,
  formLocalStorageKey,
  form,
}: FormProps<T>) => {
  const formObserver = form?.watch();
  // Autosave the create input to localStorage every 1/2 sec
  useEffect(() => {
    if (!formLocalStorageKey && !formObserver && !form) {
      return;
    }
    formLocalStorageKey && saveInLocalStorage(formLocalStorageKey, form);
  }, [formObserver]);

  return (
    <form
      onSubmit={onSubmit}
      className={clsx('flex w-full flex-col', !ignoreGap && 'gap-7', className)}
    >
      {children}
      <div
        className={clsx(
          'flex flex-row gap-7',
          centerButtons && 'justify-center'
        )}
      >
        {/* Used by multiple components to cancle a procedure */}
        {onCancel && (
          <CancelButton
            label={cancelButtonLabel}
            onClick={onCancel}
            className={cancelButtonStyle}
          />
        )}

        {/* Clear a form */}
        {onCleanForm && (
          <CancelButton
            label={'Rensa formuläret'}
            onClick={() => {
              onCleanForm();
              formLocalStorageKey &&
                localStorageTyped.remove(formLocalStorageKey);
            }}
          />
        )}
        {/* Saves a form as a draft */}
        {onSaveDraft && (
          <CancelButton
            label={'Spara utkast'}
            onClick={() => {
              onSaveDraft();
              formLocalStorageKey &&
                localStorageTyped.remove(formLocalStorageKey);
            }}
          />
        )}

        {/* Submit form */}
        <ConfirmButton
          type={buttonType}
          className={submitButtonStyle}
          label={submitButtonLabel}
          isLoading={isSubmitting}
          onClick={async () => {
            // If no trigger is provided, just submit the form
            if (!form?.trigger) {
              formLocalStorageKey &&
                localStorageTyped.remove(formLocalStorageKey);
              onSubmit();
              return;
            }

            // Trigger all fields to validate
            const result = await form.trigger();
            // If trigger returned true (no errors), submit the form
            if (result) {
              formLocalStorageKey &&
                localStorageTyped.remove(formLocalStorageKey);
              onSubmit();
              return;
            }
          }}
          disabled={isSubmitting}
        />
      </div>
    </form>
  );
};
