import { fetchWithErrorThrowing, useTypedNavigate } from 'ck-utils';

import { APIError } from 'ck-global-types';
import { PostTransferTicketV1 } from './types';
import { toast } from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';

// Mutation hook
export const useTranserTicket = () => {
  const { navigate } = useTypedNavigate();
  return useMutation({
    mutationFn: (data: PostTransferTicketV1) =>
      toast.promise(
        postTransferTicket(data),
        {
          loading: 'Loading...',
          success: 'Ticket transferred!',
          error: 'Something went wrong',
        },
        {
          duration: 8000,
        }
      ),
    onSuccess: (_, variables) => {
      navigate('/transfer-confirmation', {
        state: { email: variables.receiverEmail },
      });
    },
    // Since this is used outside of root component, we need to handle errors here
    onError: (error) => {
      if (error instanceof APIError) {
        toast.error(error.clientMessageSwedish);
      }
    },
  });
};

// Mutation function
const postTransferTicket = (data: PostTransferTicketV1) =>
  fetchWithErrorThrowing('/events/external-ticket/transfer', {
    method: 'POST',
    body: JSON.stringify(data),
    headers: new Headers({ 'content-type': 'application/json' }),
  });
