import { EventsParticipants } from 'ck-queries';
import { FieldLabel } from 'ck-components';

/**
 * Footer component with summary for ParticipantsDetailsModal
 * @param eventParticipantsData
 */

type Props = {
  eventParticipantsData: EventsParticipants;
};
export function ParticipantsSummary({ eventParticipantsData }: Props) {
  return (
    <div className='flex flex-row gap-8 pt-6 pl-3'>
      <div className='flex flex-col gap-2'>
        <div className='font-light uppercase text-gray-800'>Startdatum</div>
        <FieldLabel>{eventParticipantsData.startDate}</FieldLabel>
      </div>

      <div className='flex flex-col gap-2'>
        <div className='font-light uppercase text-gray-800'>ORGANISATÖR</div>
        <FieldLabel>{eventParticipantsData.host}</FieldLabel>
      </div>

      <div className='flex flex-col gap-2'>
        <div className='font-light uppercase text-gray-800'>BOKNINGAR</div>
        <FieldLabel>{eventParticipantsData.participantsQuota}</FieldLabel>
      </div>

      <div className='flex flex-col gap-2'>
        <div className='font-light uppercase text-gray-800'>PINKOD</div>
        <FieldLabel>{eventParticipantsData.pin}</FieldLabel>
      </div>
    </div>
  );
}
