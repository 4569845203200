import {
  AddAdminModal,
  AddCommitteeModal,
  ArchiveTab,
  RemoveAdminModal,
} from 'ck-features';
import {
  Admin,
  CommitteeFormatted,
  QK,
  getCommittees,
  useAdmins,
  useAuth,
  useCommittees,
  useDivision,
} from 'ck-queries';
import {
  BreadCrumbs,
  Button,
  FullScreenLoadingSpinner,
  MainTitle,
  TabTitle,
  TableWithPagination,
} from 'ck-components';
import {
  committeeTableStructure,
  committeeTableStructureWithOrg,
} from './types';

import { EditCommitteeModal } from 'src/features/Organisations/Committees/EditCommitteeModal';
import { Plus } from 'ck-assets';
import { Tab } from '@headlessui/react';
import { TypedNavigate } from 'ck-utils';
import { toast } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { useState } from 'react';

export function DivisionPage() {
  const { divisionId } = useParams() as {
    divisionId: string;
  };
  const { user } = useAuth();

  const isSuperAdmin = Boolean(user?.roles.includes('SuperAdmin'));

  // ---- Queries ----
  const Q_admins = useAdmins(divisionId);
  const Q_committees = useCommittees(divisionId);
  const { division, isLoading } = useDivision(divisionId);

  // ---- State ----
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [addAdminModalOpen, setAddAdminModalOpen] = useState(false);
  const [addCommitteeModalOpen, setAddCommitteeModalOpen] = useState(false);
  const [changeModeratorModalOpen, setChangeModeratorModalOpen] =
    useState(false);
  const [removeAdminModalOpen, setRemoveAdminModalOpen] = useState(false);
  const [adminToRemoveId, setAdminToRemoveId] = useState('');
  const [adminToRemoveName, setAdminToRemoveName] = useState('');
  const [rowCommitteeData, setRowCommitteeData] =
    useState<CommitteeFormatted | null>(null);

  if (isLoading) {
    return <FullScreenLoadingSpinner />;
  }

  if (!division) {
    toast.error('Sektionen kunde inte hittas.');
    return <TypedNavigate to={isSuperAdmin ? '/organisations' : '/'} />;
  }
  const { id, name, mustInheritOrgNumber } = division;

  return (
    <div className='flex w-full gap-10' key={`page-${id}`}>
      {/* ------------------ MODALS ------------------ */}
      <AddAdminModal
        modalTitle={`Lägg till admin för ${name}`}
        divisionId={id}
        isOpen={addAdminModalOpen}
        setIsOpen={setAddAdminModalOpen}
      />
      <AddCommitteeModal
        modalTitle={`Ny kommitté`}
        divisionId={id}
        isOpen={addCommitteeModalOpen}
        setIsOpen={setAddCommitteeModalOpen}
      />
      <RemoveAdminModal
        modalTitle={`Ta bort admin för ${name}`}
        name={adminToRemoveName}
        id={adminToRemoveId}
        isOpen={removeAdminModalOpen}
        setIsOpen={setRemoveAdminModalOpen}
      />
      {rowCommitteeData && (
        <EditCommitteeModal
          modalTitle={`Redigera kommittée ${rowCommitteeData.name}`}
          committeeId={rowCommitteeData.id}
          committeeName={rowCommitteeData.name}
          divisionId={id}
          isOpen={changeModeratorModalOpen}
          setIsOpen={setChangeModeratorModalOpen}
        />
      )}
      <div className='flex w-full flex-col gap-6 '>
        {isSuperAdmin && (
          <BreadCrumbs
            pathList={[['Organisationer', '/organisations'], [name]]}
          />
        )}
        <div className='flex'>
          <MainTitle label={name} />
          {/* ------- Create buttons ------ */}
          <div className='flex-shrink-0'>
            {selectedIndex === 0 && (
              <Button
                customStyle='whitespace-nowrap text-lg '
                iconLeft={Plus}
                label='Ny kommitté'
                onClick={() => setAddCommitteeModalOpen(true)}
              />
            )}
            {/* Only show Create Admin if it is superAdmin */}
            {isSuperAdmin && selectedIndex === 1 && (
              <Button
                customStyle='whitespace-nowrap text-lg'
                iconLeft={Plus}
                label='Ny admin'
                onClick={() => setAddAdminModalOpen(true)}
              />
            )}
          </div>
        </div>
        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
          <Tab.List className='my-2 flex gap-7 border-b-2 border-neutral-200 outline-none'>
            <TabTitle label='KOMMITTÉER' />
            <TabTitle label='ADMIN' />
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel className='outline-none'>
              {Q_committees.isLoading && <FullScreenLoadingSpinner />}
              {Q_committees.isError && (
                <>
                  Något gick fel vid inläsning. <br />
                  Prova uppdatera webbläsaren.
                </>
              )}
              {Q_committees.data?.length === 0 && (
                <>Inga kommittéer hittades.</>
              )}
              {Q_committees.isSuccess && Q_committees.data.length > 0 && (
                <ArchiveTab
                  tableStructure={
                    mustInheritOrgNumber
                      ? committeeTableStructure
                      : committeeTableStructureWithOrg
                  }
                  emptyStateMessage={'Det finns inga kommittéer'}
                  onEditClick={(data: CommitteeFormatted) => {
                    // Pass data to the modal and open it
                    setRowCommitteeData(data);
                    setChangeModeratorModalOpen(true);
                  }}
                  onDeleteClick={(data: CommitteeFormatted) => {
                    // Pass data to the modal and open it
                    setRowCommitteeData(data);
                    // TODO: Add delete here instead of inside the modal. This is not working.
                    // setRemoveCommitteeModalOpen(true);
                  }}
                  queryConfig={{
                    queryKey: [QK.committees, id],
                    queryFn: () => getCommittees(id),
                  }}
                />
              )}
            </Tab.Panel>
            <Tab.Panel className='outline-none'>
              {Q_admins.data && Q_admins.data.length > 0 ? (
                <TableWithPagination
                  data={Q_admins.data}
                  elementsPerPage={10}
                  tableColHeadings={['Administratörer', 'Status']}
                  tableRowDataPaths={['email', 'status']}
                  onDeleteClick={
                    // Only show delete button if it is superAdmin
                    isSuperAdmin
                      ? (data: Admin) => {
                          setAdminToRemoveId(data.inviteId);
                          setAdminToRemoveName(data.email);
                          setRemoveAdminModalOpen(true);
                        }
                      : undefined
                  }
                />
              ) : (
                'Inga administratörer hittades.'
              )}
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
}
