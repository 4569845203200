import { UseFormReturn, useFieldArray } from 'react-hook-form';

import { FormTextInput } from 'ck-components';
import { PostCreateEventV1 } from 'ck-queries';
import { ReactSVG } from 'react-svg';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { xmark } from 'ck-assets';

interface TicketFormProps {
  form: UseFormReturn<PostCreateEventV1>;
  disablePrice?: boolean;
  isEdit: boolean;
}

export const TicketForm = ({ form, disablePrice, isEdit }: TicketFormProps) => {
  const { control, register, setValue } = form;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'eventReservationDetails.tickets',
  });
  const releaseDate =
    form.getValues().eventReservationDetails.ticketReleaseTime;

  const handleAddTicket = () => {
    if (fields.length < 5) {
      append({ swedishName: '', englishName: '', price: 0 }); // Add a new ticket with initial values
    }
  };

  useEffect(() => {
    if (disablePrice) {
      // If disablePrice is true, set the price for all tickets to 0
      fields.forEach((ticket, index) => {
        setValue(`eventReservationDetails.tickets.${index}.price`, 0);
      });
    }
  }, [disablePrice, fields, setValue]);

  return (
    <div className='flex-col'>
      {fields.map((ticket, index) => (
        <>
          <div key={ticket.id} className='flex items-center gap-4'>
            <div className='w-1/2'>
              <FormTextInput
                {...register(
                  `eventReservationDetails.tickets.${index}.swedishName`
                )}
                disabled={isEdit && dayjs().isAfter(releaseDate)}
                name={`eventReservationDetails.tickets.${index}.swedishName`}
                label={index === 0 ? `Biljettyp` : undefined}
                parentForm={form}
                maxLength={40}
                placeholder={`Biljettyp ${index + 1}`}
                errorsAlwaysTakeUpSpace={false}
              />
            </div>
            <div className='flex-1'>
              <FormTextInput
                {...register(
                  `eventReservationDetails.tickets.${index}.englishName`
                )}
                disabled={isEdit && dayjs().isAfter(releaseDate)}
                name={`eventReservationDetails.tickets.${index}.englishName`}
                label={index === 0 ? `Ticket type` : undefined}
                parentForm={form}
                maxLength={40}
                placeholder={`Ticket type ${index + 1}`}
                errorsAlwaysTakeUpSpace={false}
              />
            </div>
            <div className='w-24 flex-none'>
              <FormTextInput
                {...register(`eventReservationDetails.tickets.${index}.price`)}
                name={`eventReservationDetails.tickets.${index}.price`}
                label={index === 0 ? `Biljettpris` : undefined}
                parentForm={form}
                placeholder='Ex. 100'
                options={{
                  pattern: { value: /^\d+$/, message: 'Endast siffror' },
                }}
                disabled={
                  disablePrice || (isEdit && dayjs().isAfter(releaseDate))
                }
                errorsAlwaysTakeUpSpace={false}
              />
            </div>
            {fields.length > 1 && (
              <div
                className={clsx(
                  'flex w-6 min-w-[24px] cursor-pointer items-end',
                  index === 0 && 'h-14'
                )}
              >
                <ReactSVG
                  src={xmark}
                  className='w-6 min-w-[24px] cursor-pointer'
                  onClick={() => remove(index)}
                />
              </div>
            )}
          </div>
          <div className='h-4 w-full' />
        </>
      ))}
      {fields.length >= 5 && (
        <p className='text-red-500'>Du får max ange fem biljettyper</p>
      )}
      <button
        disabled={isEdit && dayjs().isAfter(releaseDate)}
        type='button'
        onClick={handleAddTicket}
        className=' h-14 w-full rounded border-2 border-dashed bg-[#FAFAFA] p-2 text-[#ABB4B9] focus:outline-sky-500'
      >
        + Lägg till biljett
      </button>
      <div className='h-6 w-full' />
    </div>
  );
};
