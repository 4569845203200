import {
  EventReservationType,
  PostCreateEventV1,
  useEventsHosts,
  useUser,
} from 'ck-queries';
import {
  FormTextInput,
  FullScreenLoadingSpinner,
  RadioButtons,
  TicketForm,
} from 'ck-components';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useEffect, useMemo } from 'react';

import dayjs from 'dayjs';

interface Props {
  form: UseFormReturn<PostCreateEventV1>;
  isEdit?: boolean;
}

/**
 * Sub-section of the Create Event form for event type information
 *
 * @param form React Hook Form object for the Create Event form
 * @returns Section of the Create Event form for event type information
 */

export function EventTypeSection({ form, isEdit }: Props) {
  const { trigger, setValue, clearErrors } = form;
  const releaseDate = useWatch({
    control: form.control,
    name: 'eventReservationDetails.ticketReleaseTime',
  });
  const releaseEndDate = useWatch({
    control: form.control,
    name: 'eventReservationDetails.ticketReservationExpiryTime',
  });
  const startDate = useWatch({ control: form.control, name: 'date' });
  const host = useWatch({ control: form.control, name: 'host' });
  const reservationType = useWatch({
    control: form.control,
    name: 'eventReservationDetails.reservationType',
  });
  const Q_hosts = useEventsHosts();

  const selectedHostData = useMemo(
    () => Q_hosts.data?.find((committee) => committee.name === host),
    [Q_hosts.data, host]
  );

  const allowPaidEvents = useMemo(
    () =>
      selectedHostData?.functionTypes.some(
        (f) => f.functionType === 'PaidEvents'
      ),
    [selectedHostData]
  );

  const reservationType2 = form.getValues(
    'eventReservationDetails.reservationType'
  );
  console.log('TEST 2: ', reservationType2);
  console.log('TEST: ', reservationType);

  // if host changed to cannot have paid events, ensuer reservationtype is not paid events
  useEffect(() => {
    if (
      reservationType === 'Paid' &&
      (!allowPaidEvents || !selectedHostData?.orgNumber)
    ) {
      setValue('eventReservationDetails.reservationType', 'Open');
      setValue('eventReservationDetails.accountNumber', undefined);
      setValue('eventReservationDetails.resultUnit', undefined);
    } else if (reservationType === 'Paid' && allowPaidEvents) {
      setValue(
        'eventReservationDetails.accountNumber',
        selectedHostData?.accountNumber ?? ''
      );
      setValue(
        'eventReservationDetails.resultUnit',
        selectedHostData?.resultUnit ?? ''
      );
    } else {
      clearErrors('eventReservationDetails.reservationType');
    }
  }, [reservationType, selectedHostData]);

  const Q_user = useUser();

  // only show paid events as an option of host can create paid events
  const eventReservationDetailsTypeOptions: Array<{
    label: string;
    value: EventReservationType;
  }> = useMemo(
    () =>
      selectedHostData?.functionTypes.find(
        (f) => f.functionType === 'PaidEvents'
      ) && Boolean(selectedHostData?.orgNumber)
        ? [
            { label: 'Öppet', value: 'Open' },
            { label: 'Bokningsbart (Gratis)', value: 'Free' },
            { label: 'Bokningsbart (Köp)', value: 'Paid' },
          ]
        : [
            { label: 'Öppet', value: 'Open' },
            { label: 'Bokningsbart (Gratis)', value: 'Free' },
          ],
    [selectedHostData]
  );

  const gatherFoodPreferencesOptions: Array<{
    label: string;
    value: boolean;
  }> = [
    { label: 'Ja', value: true },
    { label: 'Nej', value: false },
  ];

  if (Q_user.isLoading || Q_hosts.isLoading) {
    return <FullScreenLoadingSpinner />;
  }

  return (
    <div className={'flex flex-col'}>
      <RadioButtons
        description={
          allowPaidEvents && !selectedHostData?.orgNumber
            ? 'Organisatören måste ha ett organisationsnummer för att kunna skapa betalda events'
            : undefined
        }
        disabled={isEdit}
        label='Typ av event / Type of event'
        name='eventReservationDetails.reservationType'
        parentForm={form}
        selectOptions={eventReservationDetailsTypeOptions}
      />

      {/* FIRST SECTION */}
      {reservationType !== 'Open' && (
        <div className='w-1/2 flex-col gap-6'>
          <div className='flex gap-6'>
            <div className='flex-1'>
              <FormTextInput
                label={'Första anmälningsdag'}
                disabled={isEdit && dayjs().isAfter(releaseDate)}
                parentForm={form}
                name='eventReservationDetails.ticketReleaseTime'
                minDate={dayjs().format('YYYY-MM-DD')}
                type='datetime-local'
                options={{
                  onBlur: () =>
                    trigger('eventReservationDetails.ticketReleaseTime'),
                  validate: {
                    isInFuture: () => {
                      if (!isEdit) {
                        return (
                          (dayjs(releaseDate).isAfter(dayjs()) &&
                            dayjs(releaseDate).isBefore(startDate)) ||
                          'Måste vara i framtiden och innan eventet startar.'
                        );
                      }
                      return true; // No validation needed when isEdit is true
                    },
                  },
                }}
              />
            </div>
            <div className='flex-1'>
              <FormTextInput
                label={'Sista anmälningsdag'}
                disabled={isEdit && dayjs().isAfter(releaseDate)}
                parentForm={form}
                name='eventReservationDetails.ticketReservationExpiryTime'
                minDate={dayjs().format('YYYY-MM-DD')}
                type='datetime-local'
                options={{
                  onBlur: () =>
                    trigger(
                      'eventReservationDetails.ticketReservationExpiryTime'
                    ),
                  validate: {
                    isInFuture: () =>
                      dayjs(releaseEndDate).isAfter(dayjs(releaseDate)) ||
                      'Måste vara efter biljettsläpp.',
                  },
                }}
              />
            </div>
          </div>

          {reservationType === 'Paid' && (
            <div className='flex gap-6'>
              <div className='flex-1'>
                <FormTextInput
                  label='Bokföringskonto'
                  name='eventReservationDetails.accountNumber'
                  disabled={isEdit && dayjs().isAfter(releaseDate)}
                  parentForm={form}
                  placeholder='Ange bokföringskonto'
                  maxLength={4}
                  minLength={4}
                />
              </div>
              <div className='flex-1'>
                <FormTextInput
                  label='Resultatenhet'
                  name='eventReservationDetails.resultUnit'
                  disabled={isEdit && dayjs().isAfter(releaseDate)}
                  parentForm={form}
                  placeholder='Ange resultatenhet'
                  maxLength={2}
                  minLength={2}
                />
              </div>
            </div>
          )}
        </div>
      )}

      {/* SECOND SECTION */}
      {reservationType !== 'Open' && (
        <>
          <TicketForm
            isEdit
            disablePrice={reservationType !== 'Paid'}
            form={form}
          />
          <FormTextInput
            label='Antal platser'
            name='eventReservationDetails.numberOfTickets'
            parentForm={form}
            options={{
              pattern: { value: /^\d+$/, message: 'Endast siffror' },
            }}
            placeholder='Antal platser'
            className='w-1/2'
            disabled={isEdit && dayjs().isAfter(releaseDate)}
          />
          <FormTextInput
            label='Max antal biljetter per person'
            name='eventReservationDetails.maxTicketsPerPerson'
            parentForm={form}
            options={{
              pattern: { value: /^\d+$/, message: 'Endast siffror' },
            }}
            placeholder='Biljetter per person'
            className='w-1/2'
            disabled={isEdit && dayjs().isAfter(releaseDate)}
          />
          <RadioButtons
            label='Godkänn anmälan av externa deltagare'
            name='externalParticipants'
            parentForm={form}
            selectOptions={gatherFoodPreferencesOptions}
            disabled={isEdit && dayjs().isAfter(releaseDate)}
          />

          <RadioButtons
            label='Visa Biljettantal'
            name='eventReservationDetails.showNumberOfTickets'
            parentForm={form}
            selectOptions={gatherFoodPreferencesOptions}
            disabled={isEdit && dayjs().isAfter(releaseDate)}
          />
          <RadioButtons
            label='Fråga efter matpreferenser'
            name='eventReservationDetails.gatherFoodPreferences'
            parentForm={form}
            selectOptions={gatherFoodPreferencesOptions}
            disabled={isEdit && dayjs().isAfter(releaseDate)}
          />
        </>
      )}
    </div>
  );
}
