import { ThemeTableDataPaths, useEventsIdName } from 'ck-queries';

import dayjs from 'dayjs';
import { standardDateFormat } from 'ck-utils';
import { testIds } from 'src/testIds';

type Props = {
  data: ThemeTableDataPaths;
  index: number;
};

export const ThemeArchiveDetailsInfo = ({ data, index }: Props) => {
  const Q_eventsIdName = useEventsIdName();

  const themeButtonEventName = Q_eventsIdName.data?.find(
    (event) => event.id === data.themeButtonEventId
  )?.name;

  const countdownButtonEventName = Q_eventsIdName.data?.find(
    (event) => event.id === data.countdownButtonEventId
  )?.name;

  return (
    <div className='flex flex-col justify-center gap-5 py-10'>
      <div className='flex flex-col'>
        <h1 className='text-2xl font-bold'>{data.title}</h1>
      </div>
      {index === 0 ? (
        <ModalColumn title='Tema'>
          <LabelAndText label='MÅLGRUPP' text={data.targetGroupString} />
          <LabelAndText
            label='START TEMA'
            text={data.startFormatted}
            testId={testIds.themes.modal.startTime}
          />
          <LabelAndText
            label='SLUT TEMA'
            text={data.endFormatted}
            testId={testIds.themes.modal.endTime}
          />
          {data.themeLinkUrl && (
            <LabelAndText label='LÄNK' text={data.themeLinkUrl} />
          )}
          {themeButtonEventName && (
            <LabelAndText
              label='LÄNK LEDER TILL EVENT'
              text={themeButtonEventName}
            />
          )}
        </ModalColumn>
      ) : (
        <ModalColumn title='Countdown'>
          <LabelAndText
            label='START COUNTDOWN'
            text={`${dayjs(data.countdownStartTime).format(
              standardDateFormat
            )}`}
          />
          {data.countdownLinkUrl && (
            <LabelAndText label='LÄNK' text={data.countdownLinkUrl} />
          )}
          {countdownButtonEventName && (
            <LabelAndText
              label='LÄNK LEDER TILL EVENT'
              text={countdownButtonEventName}
            />
          )}
        </ModalColumn>
      )}
      <div className='border-t border-neutral-200' />
      <div className='flex gap-2'>
        <LabelAndText label='SKAPAD AV' text={data.createdBy} />
      </div>
    </div>
  );
};

const ModalColumn = (props: {
  title: 'Tema' | 'Countdown';
  children?: React.ReactNode;
}) => (
  <div className='flex flex-col gap-6'>
    <h2 className='text-xl font-bold'>{props.title}</h2>
    {props.children}
  </div>
);

const LabelAndText = (props: {
  label: string;
  text: string | null;
  testId?: string;
}) => (
  <div className='max-w-2xl' data-testid={props.testId}>
    <h3 className='mb-1 font-light tracking-custom'>{props.label}</h3>
    <p data-testid={testIds.themes.modal.innerText}>{props.text}</p>
  </div>
);
