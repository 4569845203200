import { ArchiveTab, PollsArchiveDetailsModal } from 'ck-features';
import {
  GK,
  Poll,
  QK,
  deletePoll,
  getPolls,
  getPollsDraft,
  pollsHistoryTableStructure,
  pollsScheduledTableStructure,
} from 'ck-queries';
import { MainTitle, TabContent, TabList, TabTitle } from 'ck-components';

import { MutationConfig } from 'ck-global-types';
import { Tab } from '@headlessui/react';
import toast from 'react-hot-toast';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTypedNavigate } from 'ck-utils';

export const PollsArchivePage = () => {
  const queryClient = useQueryClient();
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  // Data that is passed to details modal
  const [rowData, setRowData] = useState<Poll | undefined>();
  const { navigate } = useTypedNavigate();

  const onRowClick = (data: Poll) => {
    setRowData(data);
    setIsDetailsOpen(true);
  };

  const onEditClick = ({ id }: Poll) => {
    if (id) {
      navigate(`/polls/edit/${id}`);
    } else {
      toast.error('Kunde inte hitta omröstningen. Prova uppdatera sidan.');
    }
  };

  const onCopyClick = async ({ id }: Poll) => {
    if (id) {
      navigate(`/polls/copy/${id}`);
    } else {
      toast.error('Kunde inte hitta notisen. Prova uppdatera sidan.');
    }
  };

  // Mutation config to be past to ArchiveTab.tsx
  const deletePollMutationConfig: MutationConfig = {
    mutationFn: deletePoll,
    onSuccess: () => {
      toast.success('Omröstningen har tagits bort');
      queryClient.invalidateQueries(GK.polls);
    },
  };

  return (
    <div className='flex flex-1 flex-col'>
      <MainTitle label={'Arkiv - Omröstning'} customStyle='pb-8' />
      <PollsArchiveDetailsModal
        isOpen={isDetailsOpen}
        setIsOpen={setIsDetailsOpen}
        data={rowData}
      />
      <Tab.Group>
        <TabList>
          <TabTitle label='SCHEMALAGDA' />
          <TabTitle label='HISTORIK' />
          <TabTitle label='UTKAST' />
        </TabList>
        <Tab.Panels>
          {/* SCHEDULED */}
          <TabContent>
            <ArchiveTab
              emptyStateMessage='Det finns inga schemalagda omröstningar.'
              queryConfig={{
                queryKey: QK.polls.scheduled,
                queryFn: () => getPolls('scheduled'),
              }}
              tableStructure={pollsScheduledTableStructure}
              mutationConfig={deletePollMutationConfig}
              onRowClick={onRowClick}
              onEditClick={onEditClick}
              onCopyClick={onCopyClick}
            />
          </TabContent>
          {/* HISTORY */}
          <TabContent>
            <ArchiveTab
              emptyStateMessage='Det finns inga historiska omröstningar.'
              queryConfig={{
                queryKey: QK.polls.history,
                queryFn: () => getPolls('history'),
              }}
              tableStructure={pollsHistoryTableStructure}
              mutationConfig={deletePollMutationConfig}
              onRowClick={onRowClick}
              onCopyClick={onCopyClick}
            />
          </TabContent>
          {/* DRAFT */}
          <TabContent>
            <ArchiveTab
              emptyStateMessage='Det finns inga utkast omröstningar.'
              queryConfig={{
                queryKey: QK.polls.draft,
                queryFn: getPollsDraft,
              }}
              tableStructure={pollsScheduledTableStructure}
              mutationConfig={deletePollMutationConfig}
              onRowClick={onRowClick}
              onEditClick={onEditClick}
              onCopyClick={onCopyClick}
            />
          </TabContent>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};
