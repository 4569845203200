import { EventCheckIns, EventParticipantsFormatted, QK } from 'ck-queries';

import { fetchWithErrorThrowing } from 'ck-utils';
import { useQuery } from '@tanstack/react-query';

export const useEventParticipantsCheckIn = (eventId: string) => {
  return useQuery({
    queryKey: QK.events.checkIns,
    queryFn: async () => getEventCheckIn(eventId),
  });
};

export const getEventCheckIn = async (
  eventId: string
): Promise<EventParticipantsFormatted[]> => {
  if (!eventId) {
    return {} as EventParticipantsFormatted[];
  }
  const res = await fetchWithErrorThrowing(`/events/${eventId}/check-ins`, {
    method: 'GET',
  });
  const data: EventCheckIns = await res.json();
  return data.participants.flatMap(
    (p) =>
      Object({
        ...p,
        status: p.status === 'CheckedIn' ? 'Incheckad' : 'Ej incheckad',
      }) satisfies EventParticipantsFormatted
  );
};
