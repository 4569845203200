import {
  AnswersGroup,
  Form,
  FormLabel,
  FormTextInput,
  RadioButtons,
  SubTitle,
  TargetGroupDropdownSelector,
  ToggleRadioButtons,
  UploadInput,
} from 'ck-components';
import {
  PollPrefill,
  PostPollsRequest,
  usePollsCreate,
  usePollsEdit,
} from 'ck-queries';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import {
  dateFormatInputCanAccept,
  getSavedPollValues,
  localStorageTyped,
  useImagePreview,
  useTypedNavigate,
} from 'ck-utils';

import { PollsCreateConfirmationModal } from 'ck-features';
import dayjs from 'dayjs';
import { defaultPollValues } from './defaults';
import { useState } from 'react';

interface Props {
  prefill: PollPrefill;
  isEdit?: boolean;
  isCopy?: boolean;
}

export const PollsCreateForm = ({ prefill, isCopy, isEdit }: Props) => {
  const { navigate } = useTypedNavigate();

  // Queries and mutations
  const M_PollsCreate = usePollsCreate();
  const M_PollsEdit = usePollsEdit(prefill?.id ?? '');

  // Form setup
  const form = useForm<PostPollsRequest>({
    mode: 'onChange',
    defaultValues: { ...(prefill ?? getSavedPollValues()) },
  });
  const { watch, reset, trigger } = form;
  const scheduleTime = watch('scheduleTime');
  const image = watch('image');
  const imageUrl = useImagePreview(image);

  // Local state
  type ScheduleChoice = 'schedule' | 'sendNow';
  const [scheduleChoice, setScheduleChoice] =
    useState<ScheduleChoice>('schedule');
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const cleanForm = () => {
    localStorageTyped.remove('FORM_CREATE_POLL');
    reset(defaultPollValues);
  };
  const onSubmit: SubmitHandler<PostPollsRequest> = (formInput) => {
    // Set scheduleTime to null if sendNow is selected
    // Indicates to backend that it should be sent immediately
    const data =
      scheduleChoice === 'sendNow'
        ? { ...formInput, scheduleTime: null }
        : formInput;

    // Check that there are two or more answers
    trigger(['answersSwedish', 'answersEnglish']);

    if (isEdit) {
      M_PollsEdit.mutateAsync(data).then(() => {
        cleanForm();
        navigate('/polls/archive');
      });
    } else {
      M_PollsCreate.mutateAsync(data).then(() => {
        cleanForm();
        navigate('/polls/archive');
      });
    }
  };

  const count = form.watch('targetGroup.count');

  const onInvalid: SubmitErrorHandler<PostPollsRequest> = (data) => {
    console.log('Submit error', data);
  };

  const onSaveDraft = () => {
    form.setValue('isDraft', true);
    form.handleSubmit(onSubmit)();
  };

  return (
    <div className='max-w-7xl'>
      <Form
        onSubmit={() => setIsConfirmationOpen(true)}
        buttonType='button'
        submitButtonLabel={'Gå vidare'}
        form={form}
        formLocalStorageKey={
          !isEdit && !isCopy ? 'FORM_CREATE_POLL' : undefined
        }
        onCleanForm={cleanForm}
        onSaveDraft={onSaveDraft}
      >
        <PollsCreateConfirmationModal
          isOpen={isConfirmationOpen}
          setIsOpen={setIsConfirmationOpen}
          onConfirmation={() => {
            form.setValue('isDraft', false);
            form.handleSubmit(onSubmit, onInvalid)();
          }}
          scheduleTime={scheduleTime}
          isSchedule={scheduleChoice === 'schedule'}
          userCount={count}
          isEdit={Boolean(isEdit)}
        />
        <div className='flex flex-1 flex-row gap-14'>
          <div className='flex-1'>
            <SubTitle label='Svenska' />
            <FormTextInput
              label={'TITEL'}
              parentForm={form}
              maxLength={40}
              name='titleSwedish'
            />
          </div>
          <div className='flex-1'>
            <SubTitle label='English' />
            <FormTextInput
              label={'TITLE'}
              parentForm={form}
              maxLength={40}
              name='titleEnglish'
            />
          </div>
        </div>
        <AnswersGroup parentForm={form} />
        <hr className='mb-6' />
        <div className='flex gap-14'>
          <div className='flex flex-1 flex-col gap-6'>
            <TargetGroupDropdownSelector name='targetGroup' form={form} />
            <RadioButtons
              label='SKICKA PUSHNOTIS'
              parentForm={form}
              name='sendPushNotification'
              selectOptions={[
                { label: 'Ja, skicka pushnotis', value: 'true' },
                { label: 'Nej, skicka inte pushnotis', value: 'false' },
              ]}
            />

            <FormLabel label='TID FÖR UTSKICK'>
              <ToggleRadioButtons
                disabled={Boolean(isEdit)}
                selectOptions={[
                  { label: 'Schemalägg', value: 'schedule' },
                  { label: 'Skicka nu', value: 'sendNow' },
                ]}
                onChange={(e) =>
                  setScheduleChoice(e.target.value as ScheduleChoice)
                }
                selectedValue={scheduleChoice}
              />
            </FormLabel>
            {scheduleChoice === 'schedule' && (
              <div className='w-60'>
                <FormTextInput
                  label='Datum och tid'
                  name='scheduleTime'
                  parentForm={form}
                  type='datetime-local'
                  minDate={dayjs().format(dateFormatInputCanAccept)}
                  options={{
                    validate: (value) =>
                      dayjs(value).isAfter(dayjs()) ||
                      'Datumet måste vara i framtiden',
                  }}
                />
              </div>
            )}
          </div>
          <div className='relative flex flex-1 flex-col'>
            <div className='absolute'>
              <UploadInput
                parentForm={form}
                label='Bild / Image'
                name='image'
                accept='.jpeg, .jpg, .png'
                emptyState='Ladda upp bild / Upload image'
                description='Recommended ratio: 600x650, 12x13.'
              />
              <img src={imageUrl} className='w-scale mb-10 h-40' alt='' />
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};
