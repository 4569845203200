import { useCommittees } from './useCommittees';
import { useMemo } from 'react';

export const useCommittee = (committeeId: string, divisionId: string) => {
  const Q_COMITTEES = useCommittees(divisionId);

  return useMemo(() => {
    if (Q_COMITTEES.data) {
      return {
        committee: Q_COMITTEES.data.find((c) => c.id === committeeId),
        isLoading: Q_COMITTEES.isLoading,
      };
    }
    return {
      committee: undefined,
      isLoading: Q_COMITTEES.isLoading,
    };
  }, [Q_COMITTEES.data, Q_COMITTEES.isLoading, committeeId, divisionId]);
};
