import {
  ArchiveTab,
  EventArchiveDetailsModal,
  ParticipantsDetailsModal,
} from 'ck-features';
import {
  EventObj,
  EventsParticipants,
  GK,
  QK,
  deleteEvent,
  deleteParticipantsList,
  getEventsActive,
  getEventsDraft,
  getEventsHistory,
  getEventsParticipants,
  useEventsDownloadParticipants,
} from 'ck-queries';
import { MainTitle, TabContent, TabList, TabTitle } from 'ck-components';
import { UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { emptyFunction, useTypedNavigate } from 'ck-utils';
import { participantsTableStructure, tableStructure } from './types';

import { Tab } from '@headlessui/react';
import toast from 'react-hot-toast';
import { useState } from 'react';

/**
 * Archive page for events
 * Uses generic ArchiveTab component with queryConfig to display data and
 * mutationConfig to delete data
 *
 */
export function EventArchivePage() {
  const queryClient = useQueryClient();
  const [eventDetailsIsOpen, setEventDetailsIsOpen] = useState(false);
  const [participantsDetailsId, setParticipantsDetailsId] = useState<string>();
  const [rowData, setRowData] = useState<EventObj | undefined>();
  const M_DownloadParticipants = useEventsDownloadParticipants();
  const { navigate } = useTypedNavigate();

  // Delete deltagarlista
  const deleteParticipantsListMutationConfig: UseMutationOptions<
    Response,
    unknown,
    string
  > = {
    mutationFn: (idToDelete: string) =>
      toast.promise(
        deleteParticipantsList(idToDelete),
        {
          loading: 'Laddar...',
          success: 'Deltagarlistan har tagits bort',
          error: 'Kunde inte ta bort deltagarlistan',
        },
        {
          duration: 8000,
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(GK.events);
    },
  };

  const deleteEventMutationConfig: UseMutationOptions<
    Response,
    unknown,
    string
  > = {
    mutationFn: (idToDelete: string) =>
      toast.promise(
        deleteEvent(idToDelete),
        {
          loading: 'Laddar...',
          success: 'Eventet har tagits bort',
          error: 'Kunde inte ta bort eventet',
        },
        {
          duration: 8000,
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(GK.events);
    },
  };

  const onEventRowClick = (data: EventObj) => {
    setRowData(data);
    setEventDetailsIsOpen(true);
  };
  const onParticipantsRowClick = (data: EventsParticipants) => {
    const { eventId } = data;
    setParticipantsDetailsId(eventId);
  };

  const onEditClick = ({ id }: EventObj) => {
    if (id) {
      navigate(`/events/edit/${id}`);
    } else {
      toast.error('Kunde inte hitta eventet. Prova uppdatera sidan.');
    }
  };

  const onCopyClick = async ({ id }: EventObj) => {
    if (id) {
      navigate(`/events/copy/${id}`);
    } else {
      toast.error('Kunde inte hitta eventet. Prova uppdatera sidan.');
    }
  };

  const onExportClick = async (event: EventsParticipants) => {
    if (event) {
      // DOWNLOAD FOOD PREFERENCES
      M_DownloadParticipants.mutateAsync(event);
    } else {
      toast.error('Kunde inte hitta eventet. Prova uppdatera sidan.');
    }
  };

  return (
    <div className='flex flex-1 flex-col'>
      <MainTitle label={'Arkiv - Event'} customStyle='pb-8' />
      <EventArchiveDetailsModal
        isOpen={eventDetailsIsOpen}
        setIsOpen={setEventDetailsIsOpen}
        data={rowData}
      />
      {participantsDetailsId && (
        <ParticipantsDetailsModal
          eventId={participantsDetailsId}
          onClose={() => {
            setParticipantsDetailsId(undefined);
          }}
          setIsOpen={emptyFunction}
          showCloseButton
        />
      )}
      <Tab.Group>
        <TabList>
          <TabTitle label='AKTIVA' />
          <TabTitle label='HISTORIK' />
          <TabTitle label='UTKAST' />
          <TabTitle label='DELTAGARLISTOR' />
        </TabList>
        <Tab.Panels>
          {/* ACTIVE */}
          <TabContent>
            <ArchiveTab
              emptyStateMessage='Det finns inga aktiva event.'
              queryConfig={{
                queryKey: QK.events.active,
                queryFn: getEventsActive,
              }}
              tableStructure={tableStructure}
              mutationConfig={deleteEventMutationConfig}
              onRowClick={onEventRowClick}
              onEditClick={onEditClick}
              onCopyClick={onCopyClick}
            />
          </TabContent>
          {/* HISTORY */}
          <TabContent>
            <ArchiveTab
              emptyStateMessage='Det finns inga historiska event.'
              queryConfig={{
                queryKey: QK.events.history,
                queryFn: getEventsHistory,
              }}
              tableStructure={tableStructure}
              mutationConfig={deleteEventMutationConfig}
              onRowClick={onEventRowClick}
              onCopyClick={onCopyClick}
            />
          </TabContent>
          {/* DRAFT */}
          <TabContent>
            <ArchiveTab
              emptyStateMessage='Det finns inga utkast event.'
              queryConfig={{
                queryKey: QK.events.draft,
                queryFn: getEventsDraft,
              }}
              tableStructure={tableStructure}
              mutationConfig={deleteEventMutationConfig}
              onRowClick={onEventRowClick}
              onCopyClick={undefined}
              onEditClick={onEditClick}
            />
          </TabContent>
          {/* DELTAGARLISTOR */}
          <TabContent>
            <ArchiveTab
              emptyStateMessage='Det finns inga deltagarlistor.'
              queryConfig={{
                queryKey: QK.events.participants,
                queryFn: getEventsParticipants,
              }}
              onRowClick={onParticipantsRowClick}
              tableStructure={participantsTableStructure}
              mutationConfig={deleteParticipantsListMutationConfig}
              onDownloadClick={onExportClick}
            />
          </TabContent>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}
