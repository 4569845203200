import { GroupType, groupToUserMap, useValidateInviteToken } from 'ck-queries';
import { LoadingSpinner, MainTitle } from 'ck-components';
import { chalmersLogo, csuGroupPicture } from 'ck-assets';

import { RegisterUserForm } from 'ck-features';
import { TypedNavigate } from 'ck-utils';
import toast from 'react-hot-toast';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

interface Props {
  group: GroupType;
}

/**
 * Page for registering a new user after receiving an invitation email from an admin.
 */
export function RegisterUserPage({ group }: Props) {
  // Get token from URL
  const { token } = useParams();

  // Email from the invitation
  const M_validateToken = useValidateInviteToken(group);

  // Before rendering the page, we need to validate the token against the backend
  useEffect(() => {
    M_validateToken.mutate({ token });
  }, [token]);

  // Show loading spinner while waiting for validate-token
  if (M_validateToken.isLoading) {
    return (
      <div className='flex h-screen w-screen items-center justify-center'>
        <LoadingSpinner width={100} />
      </div>
    );
  }

  // If token validation failed, redirect to login
  if (M_validateToken.isError) {
    toast.error('Länken är ogiltig');
    return <TypedNavigate to={'/login'} />;
  }

  // Nullcheck for token in params
  if (!token) {
    toast.error('Länken är ogiltig');
    return <TypedNavigate to={'/login'} />;
  }

  const userType = groupToUserMap[group];

  return (
    <div className='flex h-screen w-full flex-row justify-between'>
      <div className='flex h-full min-w-[500px] flex-grow flex-col items-center justify-center '>
        <div className='m-10 mt-40 flex w-full max-w-xl flex-col items-center justify-center p-12 '>
          <MainTitle label={'Skapa konto'} customStyle='mb-6' />
          {M_validateToken.data && (
            <RegisterUserForm
              email={M_validateToken.data.email}
              token={token}
              userType={userType}
            />
          )}
        </div>
        <img
          src={chalmersLogo}
          alt='The Chalmers Student Union Logo'
          className='mt-10 p-5'
        />
      </div>
      <img
        src={csuGroupPicture}
        alt='a placeholder pic'
        className='h-full w-7/12 overflow-hidden object-cover object-top'
      />
    </div>
  );
}
