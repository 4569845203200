import {
  ConfirmationModal,
  Form,
  FormTextInput,
  MainTitle,
} from 'ck-components';
import { SubmitHandler, useForm } from 'react-hook-form';

import { TypedNavigate } from 'ck-utils';
import { chalmersLogo } from 'ck-assets';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useTranserTicket } from 'src/queries/events/useTransferTicket';

interface TransferTicketFormValues {
  receiverEmail: string;
  token: string;
}

/**
 * TransferTicketPage is the page where the user can transfer their ticket
 * to another external user.
 */
export function TransferTicketPage() {
  const form = useForm<TransferTicketFormValues>({ mode: 'onBlur' });
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { handleSubmit, watch } = form;
  const receiverEmail = watch('receiverEmail');

  // Token provided in the url
  const { token } = useParams();

  const M_transferTicket = useTranserTicket();

  // Nullcheck for token in params
  if (!token) {
    toast.error('Ticket is not valid');
    return <TypedNavigate to={'/login'} />;
  }

  const onSubmit: SubmitHandler<TransferTicketFormValues> = ({
    receiverEmail,
  }) => {
    M_transferTicket.mutate({ token, receiverEmail });
  };

  return (
    <>
      <ConfirmationModal
        isOpen={showConfirmationModal}
        setIsOpen={setShowConfirmationModal}
        onConfirmation={() => {
          handleSubmit(onSubmit)();
        }}
        confirmationLabel='Transfer'
        cancelLabel='Cancel'
        buttonsInColumn
        modalTitle={`Transfer ticket`}
      >
        <p className='max-w-sm text-lg'>
          Are you sure you want to transfer the ticket to <b>{receiverEmail}</b>
          ?
        </p>
      </ConfirmationModal>
      <div className='flex h-screen w-full flex-1 flex-col items-center'>
        <div className='flex w-full flex-1 flex-col items-center justify-center'>
          <div className='flex h-full w-full flex-col items-center justify-center'>
            <div className='flex flex-col items-center justify-center pb-8'>
              <MainTitle
                label='Transfer ticket'
                customStyle='pb-8 justify-center'
              />
              <p className='max-w-[600px] pr-8 pl-8 text-center'>
                You can transfer your ticket to another alumn if you’re unable
                to attend the event. Simply provide the required information to
                complete the process.
              </p>
            </div>

            <div className='flex w-full items-center justify-center pl-8 pr-8'>
              <Form
                onSubmit={() => setShowConfirmationModal(true)}
                centerButtons
                submitButtonLabel={'Transfer ticket'}
                className='mx-auto max-w-[400px]'
                form={form}
                isSubmitting={M_transferTicket.isLoading}
                buttonType='button'
              >
                <FormTextInput
                  placeholder='example@example.se'
                  label='TRANSFER TICKET TO'
                  name='receiverEmail'
                  parentForm={form}
                  options={{
                    pattern: {
                      value:
                        // eslint-disable-next-line no-useless-escape
                        /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                      message: 'Must be a valid e-mail',
                    },
                  }}
                />
              </Form>
            </div>
          </div>
        </div>

        <div className='flex w-full items-center justify-center'>
          <img
            src={chalmersLogo}
            alt='Chalmers Student Union Logo'
            className='mb-10 w-36 pt-10'
          />
        </div>
      </div>
    </>
  );
}
