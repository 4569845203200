import { FunctionTypeIdPair } from '../function-types';

export type heldInLanguage = 'English' | 'Swedish' | 'Not applicable';
export type EventReservationType = 'Open' | 'Free' | 'Paid';

export enum targetUserTypeEnum {
  Students = 'Students',
  Alumnus = 'Alumnus',
  All = 'All',
  StudentsAtDivision = 'StudentsAtDivision',
}
export type targetUserType = keyof typeof targetUserTypeEnum;

export const targetUserTypeMap: { [key in targetUserType]: string } = {
  Students: 'Studenter',
  All: 'Studenter & Alumner',
  Alumnus: 'Alumner',
  StudentsAtDivision: 'Sektionsspecifik (studenter)',
};

export type EventIdName = {
  id: string;
  name: string;
};

export interface TicketType {
  swedishName: string;
  englishName: string;
  price: number;
}

// ? must have boolean|string for our forms
export interface EventReservationDetails {
  numberOfTickets?: number;
  accountNumber?: string;
  resultUnit?: string;
  ticketReleaseTime?: string;
  ticketReservationExpiryTime?: string;
  tickets?: Array<TicketType>;
  maxTicketsPerPerson: number | string;
  showNumberOfTickets: boolean | string;
  reservationType: EventReservationType;
  gatherFoodPreferences?: boolean | string;
  currentTicketsBooked: number;
}

export interface ModifiedEventReservationDetails
  extends Omit<
    EventReservationDetails,
    'ticketReleaseTime' | 'ticketReservationExpiryTime'
  > {
  ticketReleaseTimeUtc?: string;
  ticketReservationExpiryTimeUtc?: string;
}

// ? must have boolean|string for our forms

export interface PostCreateEventV1 {
  image: File | null;
  name: string;
  nameSwedish: string;
  location: string;
  locationSwedish: string;
  host: string;
  description: string;
  descriptionSwedish: string;
  imageCred?: string;
  categories: string[];
  startTime: string;
  endTime: string;
  date: string;
  eventLink?: string;
  linkButtonText?: string;
  linkButtonTextSwedish?: string;
  heldInLanguage: heldInLanguage;
  divisions: string[]; // 'Målgrupp' in the multi-select dropdown
  isDraft: boolean;
  targetUserType: targetUserType; // student och/eller alumn
  eventReservationDetails: Omit<
    EventReservationDetails,
    'currentTicketsBooked'
  >;
  externalParticipants: boolean | string;
}

export type Host = {
  id: string;
  name: string;
  orgNumber: string;
  functionTypes: FunctionTypeIdPair[];
  accountNumber: string | null;
  resultUnit: string | null;
};

export type GetHostsResponse = {
  hosts: Host[];
};

export type EventObj = {
  id: string;
  categoryIds: string[];
  name: string;
  nameSwedish: string;
  description: string;
  descriptionSwedish: string;
  createdBy: string;
  location: string;
  locationSwedish: string;
  eventLink?: string;
  linkButtonText?: string;
  linkButtonTextSwedish?: string;
  host: string;
  audience: string[];
  endTime: string;
  startTime: string;
  heldInLanguage: heldInLanguage;
  imageUrl?: string;
  imageCred?: string;
  updatedAt: string;
  targetUserType: targetUserType;
  externalParticipants: boolean;
  eventReservationDetails: ModifiedEventReservationDetails;
};

export type EventsParticipants = {
  title: string;
  startDate: string;
  host: string;
  participants: number;
  totalParticipants: number;
  checkedInParticipants: number;
  eventId: string;
  pin: string;
  participantsQuota: string;
};

export type ModifiedEventsParticipants = {
  title: string;
  startDate: string;
  host: string;
  participants: number;
  totalParticipants: number;
  eventId: string;
};

export type ConvertedEventsParticipants = EventsParticipants & {
  participantsQuota: string;
};

export interface EventParticipants {
  firstName: string;
  lastName: string;
  cidOrEmail: string;
  status: CheckedInStatus;
}

export type CheckedInStatus = 'CheckedIn' | 'NotCheckedIn';

export interface EventParticipantsFormatted {
  firstName: string;
  lastName: string;
  cidOrEmail: string;
  status: string;
}

export interface EventCheckIns {
  participants: EventParticipants[];
}

export type PostTransferTicketV1 = {
  token: string;
  receiverEmail: string;
};
