import { ConvertedEventsParticipants, EventsParticipants } from './types';

import { QK } from 'ck-queries';
import { fetchWithErrorThrowing } from 'ck-utils';
import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

// Query hook
export const useEventsParticipants = () =>
  useQuery({
    queryKey: QK.events.participants,
    queryFn: getEventsParticipants,
  });

// Query function
export async function getEventsParticipants(): Promise<EventsParticipants[]> {
  const res = await fetchWithErrorThrowing('/events/participants', {
    method: 'GET',
  });

  const data: { events: EventsParticipants[] } = await res.json();

  const modifiedEventsParticipants: ConvertedEventsParticipants[] =
    data.events.map((event) => ({
      ...event,
      participantsQuota: `${event.participants} / ${event.totalParticipants} st`,
    }));

  modifiedEventsParticipants.sort((a, b) => {
    const startDateA: Date = new Date(a.startDate);
    const startDateB: Date = new Date(b.startDate);
    return startDateB.getTime() - startDateA.getTime();
  });

  return modifiedEventsParticipants;
}

export const useEventParticipantsByEventId = (eventId?: string) => {
  const Q_EVENT_PARTICIPANTS = useEventsParticipants();
  return useMemo(() => {
    if (!Q_EVENT_PARTICIPANTS.data || !eventId) {
      return undefined;
    }
    const data = Q_EVENT_PARTICIPANTS.data.find((d) => d.eventId === eventId);
    return data;
  }, [Q_EVENT_PARTICIPANTS.data, eventId]);
};
