// This avoids typos when using query keys with react-query.

// Group query keys
const group = {
  notifications: 'notifications',
  themes: 'themes',
  events: 'events',
  polls: 'polls',
  alarm: 'alarms',
};

// Group keys used to invalidate an entire group of queries
export const GK = {
  notifications: [group.notifications],
  themes: [group.themes],
  events: [group.events],
  polls: [group.polls],
};

// Query keys
export const QK = {
  groups: ['groups'],
  user: ['user'],
  refreshToken: ['refreshToken'],
  targetGroups: ['targetGroups'],
  notifications: {
    history: [group.notifications, 'history'],
    scheduled: [group.notifications, 'scheduled'],
    prefill: [group.notifications, 'prefill'],
    draft: [group.notifications, 'draft'],
  },
  alarm: {
    prefill: [group.alarm, 'prefill'],
  },
  themes: {
    active: [group.themes, 'active'],
    upcoming: [group.themes, 'upcoming'],
    history: [group.themes, 'history'],
    draft: [group.themes, 'draft'],
    prefill: [group.themes, 'prefill'],
  },
  events: {
    active: [group.events, 'active'],
    checkIns: [group.events, 'check-in'],
    history: [group.events, 'history'],
    divisions: [group.events, 'divisions'],
    userSpecificDivisions: [group.events, 'userSpecificDivisions'],
    hosts: [group.events, 'hosts'],
    categories: [group.events, 'categories'],
    idName: [group.events, 'idName'],
    draft: [group.events, 'draft'],
    userTypes: [group.events, 'userTypes'],
    participants: [group.events, 'participants'],
    prefill: [group.events, 'prefill'],
  },
  polls: {
    scheduled: [group.polls, 'scheduled'],
    history: [group.polls, 'history'],
    draft: [group.polls, 'draft'],
    prefill: [group.polls, 'prefill'],
  },
  committees: ['committees'],
  divisions: ['divisions'],
  admins: ['admins'],
  functionTypes: ['functionTypes'],
};
