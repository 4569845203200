import { InformationModalProps, Table } from 'ck-components';

import Modal from 'src/components/Modals/ModalBase/Modal';
import { ParticipantsSummary } from './components/ParticipantsSummary';
import { checkInsTableStructure } from 'src/pages/EventsPages/types';
import { useEventParticipantsByEventId } from 'ck-queries';
import { useEventParticipantsCheckIn } from 'src/queries/events/useEventParticipantsCheckIn';

interface Props extends Omit<InformationModalProps, 'isOpen'> {
  eventId: string;
}

/**
 * modal closes if there is no eventId
 * @param param0
 * @returns
 */
export const ParticipantsDetailsModal = ({ eventId, ...modalProps }: Props) => {
  const eventParticipantsData = useEventParticipantsByEventId(eventId);
  const participants = useEventParticipantsCheckIn(eventId);

  if (!eventParticipantsData || !participants.data) {
    return <></>;
  }

  const tableStructure = checkInsTableStructure(
    eventParticipantsData.checkedInParticipants,
    eventParticipantsData.totalParticipants
  );
  return (
    <Modal {...modalProps} isOpen={Boolean(eventId)} customStyle='min-w-[70vw]'>
      <h2 className='text-xl font-bold'>{`Deltagarlista - ${eventParticipantsData.title}`}</h2>
      <Table
        data={participants.data}
        tableColHeadings={tableStructure.map(({ title }) => title)}
        tableRowDataPaths={tableStructure.map(({ dataPath }) => dataPath)}
        isScroll
        fixedHeight='50vh'
        emptyStateMessage={'Inga deltagare är anmälda till eventet'}
        error={participants.isError}
      />
      <ParticipantsSummary eventParticipantsData={eventParticipantsData} />
    </Modal>
  );
};
