import { EventIdName, EventObj, PostCreateEventV1 } from 'ck-queries';

import dayjs from 'dayjs';
import { fetchImageFromRemoteBlobStorage } from './fetchImageFromBlobStorage';
import { removeNullUndefinedProperties } from './removeNullUndefinedProperties';

export const formatEventPrefill = async (
  rawPrefill: EventObj | undefined,
  isCopy: boolean,
  categories: EventIdName[] | undefined
) => {
  // Nullcheck
  if (!rawPrefill) return Promise.resolve({ prefill: undefined });
  // Remove null and undefined properties
  console.log('raw prefill', rawPrefill);
  const prefill = removeNullUndefinedProperties(rawPrefill);
  const date = dayjs(prefill.startTime).format('YYYY-MM-DD');
  const startTime = dayjs(prefill.startTime).format('HH:mm');
  const endTime = dayjs(prefill.endTime).format('HH:mm');
  const ticketReleaseTime = dayjs(
    prefill.eventReservationDetails.ticketReleaseTimeUtc
  ).format('YYYY-MM-DDTHH:mm');
  const ticketReservationExpiryTime = dayjs(
    prefill.eventReservationDetails.ticketReservationExpiryTimeUtc
  ).format('YYYY-MM-DDTHH:mm');
  const gatherFoodPreferences =
    prefill.eventReservationDetails.gatherFoodPreferences?.toString();

  const externalParticipants = prefill.externalParticipants?.toString();
  const showNumberOfTickets =
    prefill.eventReservationDetails.showNumberOfTickets?.toString();

  // Fetch image from blob storage
  const image = await fetchImageFromRemoteBlobStorage(rawPrefill.imageUrl);

  const nameSwedish = isCopy
    ? `Kopia av ${prefill.nameSwedish}`
    : prefill.nameSwedish;

  const name = isCopy ? `Copy of ${prefill.name}` : prefill.name;
  const eventReservationDetails = prefill.eventReservationDetails;

  // Omit currentTicketsBooked and release/expiry time utc from eventReservationDetails

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    currentTicketsBooked,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ticketReleaseTimeUtc,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ticketReservationExpiryTimeUtc,
    ...cleanedEventReservationDetails
  } = eventReservationDetails;

  // Omit createdBy from eventReservationDetails
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { createdBy, ...prefillWithoutCreatedBy } = prefill;
  // Need to display categories after their name, convert ids to names
  const categoryNames = categories
    ?.filter(({ id }) => prefill.categoryIds.includes(id))
    .map((c) => c.name);
  const formattedPrefill: Partial<PostCreateEventV1 & { id: string }> = {
    ...prefillWithoutCreatedBy,
    name,
    nameSwedish,
    date,
    startTime,
    endTime,
    image,
    divisions: prefill.audience, // Mismatch in API
    categories: categoryNames,
    externalParticipants,
    eventReservationDetails: {
      ...cleanedEventReservationDetails,
      showNumberOfTickets,
      ticketReleaseTime,
      ticketReservationExpiryTime,
      gatherFoodPreferences,
    },
  };

  return { prefill: formattedPrefill };
};
