import {
  ConfirmationModal,
  LoadingSpinner,
  RowComponentActionProps,
  Table,
  TableWithPagination,
} from 'ck-components';
import {
  QueryKey,
  UseMutationOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';

import { EventsParticipants } from 'ck-queries';
import { testIds } from 'src/testIds';
import { toast } from 'react-hot-toast';
import { useState } from 'react';

type QueryConfig<TData> = {
  queryKey: QueryKey;
  queryFn?: () => Promise<TData>;
};

type TableStructure = {
  title: string;
  dataPath: string;
};

interface Props<TData extends Array<any>> extends RowComponentActionProps {
  emptyStateMessage: string;
  queryConfig: QueryConfig<TData>;
  mutationConfig?: UseMutationOptions<Response, unknown, string>;
  tableStructure: TableStructure[];
  customDataType?: any;
  isScroll?: boolean;
  fixedHeight?: string;
}

interface DefaultData {
  id: string;
  title: string;
  nameSwedish: string;
  titleSwedish: string;
}

/**
 * Generic archive tab component with table and pagination
 * Uses generic queryConfig to fetch data and mutationConfig to delete data
 *
 * @param emptyStateMessage: Message to display if no data is found
 * @param tableStructure: Structure of table to display. Title and dataPath.
 * @param queryConfig: Query config to fetch data
 * @param mutationConfig: Mutation config to delete data
 * @param onRowClick: Callback to run when row is clicked. Is passed the row data.
 * @param onEditClick: Callback to run when edit button is clicked. Is passed the row data.
 * @param onCopyClick: Callback to run when copy button is clicked. Is passed the row data.
 * @param conditionDisableCopyClick condition to hide copy-button depending on row data
 * @param conditionDisableEditClick condition to hide edit-button depending on row data
 * @param onDownloadClick: Callback to run when download button is clicked. Is passed the row data.
 * @param isScroll condition to enable scrolling, [notice!] must set @param fixedHeight as well.
 * @param fixedHeight must be set in order to enable scroll
 * @returns
 */
export const ArchiveTab = <TData extends Array<any>>({
  emptyStateMessage,
  tableStructure,
  queryConfig,
  mutationConfig,
  onRowClick,
  onEditClick,
  conditionDisableEditClick,
  onCopyClick,
  conditionDisableCopyClick,
  customLastColumn,
  onCustomClick,
  onDownloadClick,
  isScroll,
  fixedHeight,
}: Props<TData>) => {
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [idToDelete, setIdToDelete] = useState<string | null>();
  const [titleOfDelete, setTitleOfDelete] = useState<string>();

  // Generic mutation to delete table row
  const mutation = useMutation(mutationConfig || {});

  // Generic query to get table data
  const query = useQuery(queryConfig);

  if (query.isLoading || !query.data) {
    return (
      <div className='mt-10'>
        <LoadingSpinner
          width={100}
          data-testid={testIds.other.loadingSpinner}
        />
      </div>
    );
  }

  return (
    <>
      {idToDelete && (
        <ConfirmationModal
          isOpen={showDeleteConfirmationModal}
          setIsOpen={setShowDeleteConfirmationModal}
          onConfirmation={() => {
            mutation.mutate(idToDelete);
            setIdToDelete(null);
          }}
          isDelete
          confirmationLabel='Ta bort'
          modalTitle={`Radera ${titleOfDelete}`}
        >
          <p className='max-w-sm text-lg'>
            Är du säker på att du vill ta bort {titleOfDelete}?
          </p>
        </ConfirmationModal>
      )}
      {isScroll ? (
        <Table
          data={query.data ?? []}
          tableColHeadings={tableStructure.map(({ title }) => title)}
          tableRowDataPaths={tableStructure.map(({ dataPath }) => dataPath)}
          onRowClick={onRowClick ? (data: any) => onRowClick(data) : undefined}
          onDeleteClick={
            mutationConfig
              ? (data: DefaultData | EventsParticipants) => {
                  if ('id' in data && data.id) {
                    setIdToDelete(data.id);
                    setTitleOfDelete(
                      data.title
                        ? data.title
                        : data.nameSwedish ?? data.titleSwedish
                    );
                    setShowDeleteConfirmationModal(true);
                  } else if ('eventId' in data && data.eventId) {
                    setIdToDelete(data.eventId);
                    setTitleOfDelete('deltagarlistan');
                    setShowDeleteConfirmationModal(true);
                  } else {
                    toast.error(
                      'Inget id kunde hittas. Prova uppdatera webbläsaren.'
                    );
                  }
                }
              : undefined
          }
          onEditClick={
            onEditClick ? (data: any) => onEditClick(data) : undefined
          }
          onCopyClick={
            onCopyClick ? (data: any) => onCopyClick(data) : undefined
          }
          conditionDisableCopyClick={conditionDisableCopyClick}
          conditionDisableEditClick={conditionDisableEditClick}
          onDownloadClick={
            onDownloadClick ? (data: any) => onDownloadClick(data) : undefined
          }
          onCustomClick={
            onCustomClick ? (data: any) => onCustomClick(data) : undefined
          }
          customLastColumn={customLastColumn}
          isScroll={isScroll}
          fixedHeight={fixedHeight}
          emptyStateMessage={emptyStateMessage}
          error={query.isError}
        />
      ) : (
        <TableWithPagination
          data={query.data ?? []}
          elementsPerPage={10}
          tableColHeadings={tableStructure.map(({ title }) => title)}
          tableRowDataPaths={tableStructure.map(({ dataPath }) => dataPath)}
          onRowClick={onRowClick ? (data: any) => onRowClick(data) : undefined}
          onDeleteClick={
            mutationConfig
              ? (data: DefaultData | EventsParticipants) => {
                  if ('id' in data && data.id) {
                    setIdToDelete(data.id);
                    setTitleOfDelete(
                      data.title
                        ? data.title
                        : data.nameSwedish ?? data.titleSwedish
                    );
                    setShowDeleteConfirmationModal(true);
                  } else if ('eventId' in data && data.eventId) {
                    setIdToDelete(data.eventId);
                    setTitleOfDelete('deltagarlistan');
                    setShowDeleteConfirmationModal(true);
                  } else {
                    toast.error(
                      'Inget id kunde hittas. Prova uppdatera webbläsaren.'
                    );
                  }
                }
              : undefined
          }
          onEditClick={
            onEditClick ? (data: any) => onEditClick(data) : undefined
          }
          onCopyClick={
            onCopyClick ? (data: any) => onCopyClick(data) : undefined
          }
          conditionDisableCopyClick={conditionDisableCopyClick}
          conditionDisableEditClick={conditionDisableEditClick}
          onDownloadClick={
            onDownloadClick ? (data: any) => onDownloadClick(data) : undefined
          }
          onCustomClick={
            onCustomClick ? (data: any) => onCustomClick(data) : undefined
          }
          customLastColumn={customLastColumn}
        />
      )}
    </>
  );
};
