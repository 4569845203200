import { MainTitle } from 'ck-components';
import { transferred } from 'ck-assets';
import { useLocation } from 'react-router-dom';

export function TransferTicketConfirmationPage() {
  const location = useLocation();
  const email = location.state?.email;

  return (
    <div className='flex flex-1 flex-col items-center justify-center'>
      <img
        src={transferred}
        alt='Transfer ticket'
        className='mt-10 drop-shadow-lg'
      />
      <div className='items-center justify-center'>
        <MainTitle
          label='Your ticket was transferred!'
          customStyle='pb-8 pt-8 text-center leading-none'
        />
      </div>
      <p className='pr-8 pl-8 text-center'>
        All done! Your ticket was successfully transferred to <b>{email}</b>.
      </p>
    </div>
  );
}
