import RowComponent, { RowComponentActionProps } from './RowComponent';

import clsx from 'clsx';

export type ArrElement<T> = T extends (infer ElementType)[]
  ? ElementType
  : never;
export interface TableProps<T> extends RowComponentActionProps {
  tableColHeadings: string[];
  tableRowDataPaths: Array<keyof ArrElement<T>>;
  data: T;
  customStyleHeaders?: string;
  isScroll?: boolean;
  fixedHeight?: string;
  emptyStateMessage?: string;
  error?: boolean;
}
export type TableArray<T> = Array<{ [key in keyof ArrElement<T>]: any }>;

/**
 * Our styled and customized dynamic data table. Accepts a data object of any size and displays it
 * in accordance with the design mock.
 *
 * @param tableColHeadings an array of strings which will be rendered as table headings
 * @param tableRowDataPaths an array of strings containing the paths which the data will map against
 * @param data the data object to be rendered within the table
 * @param onRowClick an optional callback which is called when a row has been Clicked
 * @param onDeleteClick an optional callback with is called when the trash icon is Clicked
 * @param onCopyClick an optional callback with is called when the copy icon is Clicked
 * @param onDownloadClick an optional callback with is called when the copy icon is Clicked
 * @param conditionDisableCopyClick condition to hide copy-button depending on row data
 * @param conditionDisableEditClick condition to hide edit-button depending on row data
 * @param isScroll condition to enable scrolling, [notice!] must set @param fixedHeight as well.
 * @param fixedHeight must be set in order to enable scroll
 */

export function Table<T extends TableArray<T>>({
  tableColHeadings,
  tableRowDataPaths,
  data,
  onRowClick,
  customStyleHeaders,
  isScroll,
  fixedHeight,
  onDeleteClick,
  onEditClick,
  onCopyClick,
  conditionDisableEditClick,
  conditionDisableCopyClick,
  onDownloadClick,
  customLastColumn,
  onCustomClick,
  emptyStateMessage,
  error,
}: TableProps<T>) {
  const editFunctionExists =
    Boolean(onDeleteClick) ||
    Boolean(onCustomClick) ||
    Boolean(onEditClick) ||
    Boolean(onCopyClick) ||
    Boolean(onDownloadClick);
  // If we have a delete or edit function, add 'ÄNDRA' in the last heading
  const tableHeadings = editFunctionExists
    ? [...tableColHeadings, 'ÄNDRA']
    : tableColHeadings;

  return (
    <div
      className={clsx(
        isScroll && fixedHeight && `max-h-[${fixedHeight}] w-full overflow-auto`
      )}
    >
      <table className='min-w-full table-fixed border-separate border-spacing-y-2 text-left'>
        <thead className='sticky top-0 z-10 w-full table-fixed bg-white'>
          <tr className='w-full'>
            {tableHeadings.map((heading, index) => (
              <th
                key={`${heading}-${index}`}
                scope='col'
                className={clsx(
                  'px-4 py-2 text-left text-base font-light uppercase text-gray-800 first:pl-7 last:pr-7',
                  customStyleHeaders,
                  editFunctionExists && 'last:text-end'
                )}
              >
                {heading}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className='w-full text-lg'>
          {data && data.length > 0 ? (
            data.map((dataEntry, index) => (
              <RowComponent
                key={index}
                data={dataEntry}
                dataPaths={tableRowDataPaths}
                onRowClick={onRowClick}
                onDeleteClick={onDeleteClick}
                onEditClick={onEditClick}
                onCopyClick={onCopyClick}
                conditionDisableEditClick={conditionDisableEditClick}
                conditionDisableCopyClick={conditionDisableCopyClick}
                onDownloadClick={onDownloadClick}
                customLastColumn={customLastColumn}
                onCustomClick={onCustomClick}
              />
            ))
          ) : (
            <tr>
              <td
                colSpan={tableHeadings.length}
                className='border-t border-gray-300 py-6 pb-40 text-center font-light italic text-gray-500'
              >
                {error
                  ? 'Något gick fel, vänligen försök igen'
                  : emptyStateMessage ?? 'Ingen data finns'}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
