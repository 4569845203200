import {
  ClickableText,
  Description,
  Form,
  FormLabel,
  FormTextInput,
  Icon,
  InformationModal,
  InformationModalProps,
  Label,
  ToggleRadioButtons,
} from 'ck-components';
import {
  FunctionTypeIdPair,
  PostCreateCommittee,
  PostEditCommittee,
  useCommittee,
  useDivision,
  useEditCommittee,
  useFunctionTypes,
  useUser,
} from 'ck-queries';
import { Trash, UserGroup } from 'ck-assets';
import { useEffect, useMemo, useState } from 'react';

import { ReactSVG } from 'react-svg';
import { RemoveCommitteeModal } from './RemoveCommitteeModal';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';

export const EditCommitteeModal = (
  props: Omit<InformationModalProps, 'children'> & {
    divisionId: string;
    committeeId: string;
    committeeName: string;
  }
) => {
  const form = useForm<PostEditCommittee>();
  const M_editCommittee = useEditCommittee();
  const { division } = useDivision(props.divisionId);
  const { committee } = useCommittee(props.committeeId, props.divisionId);

  const { handleSubmit, reset } = form;
  const [allowCreatePaidEvent, setAllowCreatePaidEvent] = useState(false);
  const [removeCommitteeModalOpen, setRemoveCommitteeModalOpen] =
    useState(false);
  const allFunctionTypes = useFunctionTypes();
  const Q_User = useUser();

  const allowCreatePaidEventUser = useMemo(
    () => Q_User.data?.functions.includes('CreatePaidEventsAllowed'),
    [Q_User.data]
  );

  // Set initial data when we have the committee
  useEffect(() => {
    if (committee) {
      if (committee.paidEvents === 'Ja') {
        setAllowCreatePaidEvent(true);
      }
      form.reset({
        ...committee,
        email: committee.activeEmail,
      });
    }
  }, [committee]);

  const onEdit = async (formData: PostEditCommittee) => {
    if (!committee) {
      throw Error('No committee found');
    }
    // Compile permissions
    const functionTypes: PostCreateCommittee['functionTypes'] = [];
    if (allowCreatePaidEvent && allowCreatePaidEventUser) {
      const paidEventPermissionId = allFunctionTypes.find(
        (ft: FunctionTypeIdPair) => ft.functionType === 'PaidEvents'
      )?.id;
      paidEventPermissionId && functionTypes.push(paidEventPermissionId);
    }

    // Add functionTypes
    const postData = {
      ...formData,
      accountNumber: allowCreatePaidEvent ? formData.accountNumber : null,
      resultUnit: allowCreatePaidEvent ? formData.resultUnit : null,
      id: committee?.id,
      functionTypes,
    };
    return M_editCommittee.mutateAsync(postData);
  };

  const onSubmit = (formData: PostEditCommittee) => {
    toast
      .promise(
        onEdit(formData),
        {
          loading: 'Laddar...',
          success: `Kommittéen är uppdaterad!`,
          error: 'Kunde inte uppdatera kommittén',
        },
        {
          duration: 8000,
        }
      )
      .then(() => {
        // Reset form
        reset();

        // Close edit modal
        props.setIsOpen(false);
      });
  };

  const canCreatePaidEvents: Array<{
    label: string;
    value: number;
  }> = [
    { label: 'Ja', value: Number(true) },
    { label: 'Nej', value: Number(false) },
  ];

  useEffect(() => {
    if (props.divisionId && division?.mustInheritOrgNumber) {
      form.setValue('orgNumber', division.orgNumber);
    }
  }, [props.committeeId, division]);

  return (
    <>
      {removeCommitteeModalOpen && committee && (
        <RemoveCommitteeModal
          modalTitle={'Ta bort kommitté'}
          committeeId={committee.id}
          committeeName={committee?.name}
          isOpen={removeCommitteeModalOpen}
          setIsOpen={setRemoveCommitteeModalOpen}
          onClose={() => props.setIsOpen(false)}
        />
      )}
      <InformationModal
        {...props}
        customStyle='w-[692px]'
        onClose={reset}
        showCloseButton={false}
      >
        <Form
          submitButtonLabel='Spara ändringar'
          showCancelButton={true}
          form={form}
          onSubmit={handleSubmit(onSubmit)}
          onCancel={() => {
            reset();
            props.setIsOpen(false);
          }}
          centerButtons
          isSubmitting={M_editCommittee.isLoading}
        >
          <FormTextInput
            label='MODERATOR'
            name='email'
            parentForm={form}
            errorsAlwaysTakeUpSpace={false}
            options={{
              required: 'E-postadress måste anges',
              pattern: {
                value: /^\S+@\S+$/i,
                message: 'Ogiltig e-postadress',
              },
            }}
          />

          <FormTextInput
            name='orgNumber'
            parentForm={form}
            label='ORGANISATIONSNUMMER'
            errorsAlwaysTakeUpSpace={false}
            disabled={division?.mustInheritOrgNumber}
            options={{
              required: 'Organisationsnummer måste anges',
              /*             pattern: {
              value: /^[0-9]*$/i,
              message: 'Ogiltigt organisationsnummer',
            }, */
            }}
          />
          {division && !division.mustInheritOrgNumber && (
            <div
              onClick={() => {
                form.setValue('orgNumber', division.orgNumber);
              }}
              className='-mt-5  w-full rounded border border-gray-300   focus:outline-sky-500'
            >
              <div className='px-4 py-2'>
                <Label>ÄRV org.nr från sektion</Label>
              </div>
              <div className='flex w-full flex-row items-center justify-start gap-4 bg-sky-100 p-3 '>
                <Icon src={UserGroup} size={9} />
                <div className='clex flex-column shrink items-start justify-start'>
                  <div className='bold'>{division.name}</div>
                  <Description>{division.orgNumber}</Description>
                </div>
              </div>
            </div>
          )}

          {allowCreatePaidEventUser && (
            <>
              <FormLabel label={'TILLÅT EVENT MED BILJETTKÖP'}>
                <ToggleRadioButtons
                  selectOptions={canCreatePaidEvents}
                  onChange={(event) =>
                    setAllowCreatePaidEvent(
                      Boolean(Number(event.currentTarget.value))
                    )
                  }
                  selectedValue={Number(allowCreatePaidEvent)}
                />
              </FormLabel>
              {allowCreatePaidEvent && (
                <>
                  <FormTextInput
                    label='BOKFÖRINGSKONTO'
                    name='accountNumber'
                    parentForm={form}
                    errorsAlwaysTakeUpSpace={false}
                    maxLength={4}
                    minLength={4}
                    options={{
                      required: 'Bokföringskonto måste anges',
                      pattern: {
                        value: /^[0-9]*$/i,
                        message: 'Ogiltigt bokföringskonto',
                      },
                    }}
                  />

                  <FormTextInput
                    name='resultUnit'
                    label='RESULTATENHET'
                    parentForm={form}
                    errorsAlwaysTakeUpSpace={false}
                    maxLength={2}
                    minLength={2}
                    options={{
                      required: 'Resultatenehet måste anges',
                      pattern: {
                        value: /^[0-9]*$/i,
                        message: 'Ogiltig resultatenehet',
                      },
                    }}
                  />
                </>
              )}
            </>
          )}
        </Form>
        <div
          onClick={() => setRemoveCommitteeModalOpen(true)}
          className='mt-8 flex flex-row items-center justify-center'
        >
          <ReactSVG
            title='Delete button'
            src={Trash}
            className='w-5 cursor-pointer flex-col'
            beforeInjection={(svg) => {
              svg.setAttribute('stroke', '#D8004D'); // Ensures stroke color
            }}
          />
          <ClickableText
            label='Ta bort kommittée'
            onPress={() => setRemoveCommitteeModalOpen(true)}
            customStyle='text-[#D8004D] active:text-[#A3003A] font-bold text-md'
          />
        </div>
      </InformationModal>
    </>
  );
};
