import {
  FormTextInput,
  FullScreenLoadingSpinner,
  MultiSelectDropdown,
  RadioButtons,
  SingleSelectDropdown,
  UploadImagePreview,
  UploadInput,
} from 'ck-components';
import {
  PostCreateEventV1,
  heldInLanguage,
  targetUserType,
  targetUserTypeEnum,
  targetUserTypeMap,
  useAuth,
  useEventsCategories,
  useEventsDivisions,
  useEventsHosts,
  useEventsUserTypes,
  useUser,
  useUserSpecificEventsDivisions,
} from 'ck-queries';
import { dayjsTime, useImagePreview } from 'ck-utils';

import { UseFormReturn } from 'react-hook-form';
import dayjs from 'dayjs';
import { useEffect } from 'react';

interface Props {
  form: UseFormReturn<PostCreateEventV1>;
  isEdit?: boolean;
}

/**
 * Sub-section of the Create Event form for organisation information
 *
 * @param form React Hook Form object for the Create Event form
 * @returns Section of the Create Event form for organisation information
 */

export function OrganisationSection({ form, isEdit = false }: Props) {
  const { watch, trigger, setValue, getValues } = form;
  const date = watch('date');
  const image = watch('image');
  const targetUserType = watch('targetUserType');
  const imageUrl = useImagePreview(image);

  const Q_eventsDivisions = useEventsDivisions();
  const Q_userSpecificEventsDivisions = useUserSpecificEventsDivisions();
  const Q_hosts = useEventsHosts();
  const Q_categories = useEventsCategories();
  const Q_user = useUser();
  const Q_userTypes = useEventsUserTypes();
  const ticketReleaseTime =
    form.getValues().eventReservationDetails.ticketReleaseTime;

  useEffect(() => {
    if (targetUserType === 'StudentsAtDivision') {
      setValue(
        'divisions',
        getValues('divisions').filter((d) => d !== 'Alla sektioner')
      );
    }
  }, [targetUserType]);

  const { user } = useAuth();
  const heldInLanguageOptions: Array<{ label: string; value: heldInLanguage }> =
    [
      { label: 'Engelska', value: 'English' },
      { label: 'Svenska', value: 'Swedish' },
      { label: 'Inte applicerbart', value: 'Not applicable' },
    ];

  if (
    Q_categories.isLoading ||
    Q_hosts.isLoading ||
    !Q_hosts.data ||
    Q_eventsDivisions.isLoading ||
    Q_userTypes.isLoading ||
    Q_user.isLoading ||
    Q_userSpecificEventsDivisions.isLoading
  ) {
    return <FullScreenLoadingSpinner />;
  }

  const isCommitteeModerator = user?.roles.includes('CommitteeModerator');

  // This feels stupid. There has to be a better thing to do. But I do not have time I have a vacation to go to and a deadline to reach ¯\_(ツ)_/¯ // Sabine 11/7-24
  const userTypeSelectOptions: Array<{
    label: string;
    value: targetUserType;
  }> = [
    { label: targetUserTypeMap['Students'], value: 'Students' },
    {
      label: targetUserTypeMap['StudentsAtDivision'],
      value: 'StudentsAtDivision',
    },
    { label: targetUserTypeMap['All'], value: 'All' },
    { label: targetUserTypeMap['Alumnus'], value: 'Alumnus' },
  ];

  return (
    <>
      <div className='flex gap-14'>
        <div className='flex-1'>
          {Q_hosts.data && ticketReleaseTime && (
            <SingleSelectDropdown
              disabled={
                (isEdit && new Date(ticketReleaseTime) < new Date()) ||
                Q_hosts.data?.length === 0 ||
                (isEdit &&
                  form.getValues().eventReservationDetails.reservationType ===
                    'Paid')
              }
              withSearch
              label='Organisatör / Host'
              description={
                !isCommitteeModerator
                  ? `Kommitté som annordnar. Skapa en kommité under "Organisationer" om listan är tom.`
                  : ''
              }
              name='host'
              form={form}
              values={Q_hosts.data.map((host) => host.name)}
              labelFromValue={(value) => value}
            />
          )}
          {Q_categories.data && (
            <MultiSelectDropdown
              name='categories'
              parentForm={form}
              optionsData={Q_categories.data.map(({ name }) => name)}
              label='Eventkategori / Event category'
              description='Välj en eller flera kategorier'
            />
          )}
          <FormTextInput
            label={'Datum'}
            parentForm={form}
            name='date'
            minDate={dayjs().format('YYYY-MM-DD')}
            type='date'
            options={{
              onBlur: () => trigger('startTime'),
            }}
          />
          <div className='flex gap-6'>
            {/* TODO: Refactor to an independent FormDateInput component */}

            <FormTextInput
              label={'Starttid'}
              parentForm={form}
              name='startTime'
              type='time'
              options={{
                validate: {
                  isInFuture: (newStartTime: string) =>
                    dayjs(date)
                      .set('h', Number(newStartTime.split(':')[0]))
                      .set('m', Number(newStartTime.split(':')[1]))
                      .isAfter(dayjs()) || 'Start måste vara i framtiden.',
                },
                onChange: ({ target: { value } }) =>
                  // Set endTime to startTime + 1 hour
                  setValue(
                    'endTime',
                    dayjsTime(value).add(1, 'hour').format('HH:mm')
                  ),
                onBlur: () => {
                  trigger('startTime');
                  trigger('endTime');
                },
              }}
            />
            <FormTextInput
              label={'Sluttid'}
              parentForm={form}
              name='endTime'
              type='time'
            />
          </div>
          <RadioButtons
            label='Eventets språk / Event language'
            name='heldInLanguage'
            parentForm={form}
            selectOptions={heldInLanguageOptions}
          />
        </div>
        <div className='flex-1'>
          <UploadInput
            parentForm={form}
            description='Bilden ska vara minst 500 px bred. Undvik att ladda upp logotyper, bilden beskärs automatiskt.'
            label='Foto / Image (valfri)'
            name='image'
            accept='.jpeg, .jpg, .png'
            emptyState='Ladda upp bild / Upload image'
          />
          {image && image?.size && (
            <FormTextInput
              label='Photo cred'
              name='imageCred'
              parentForm={form}
              maxLength={40}
            />
          )}
          <UploadImagePreview imageUrl={imageUrl} />
        </div>
      </div>
      <div>
        <RadioButtons
          label='Visas För'
          name='targetUserType'
          parentForm={form}
          selectOptions={userTypeSelectOptions}
          valueToDisable={
            !Q_userSpecificEventsDivisions.data ||
            Q_userSpecificEventsDivisions.data.length < 1
              ? targetUserTypeEnum.StudentsAtDivision
              : undefined
          }
        />
        <div className='flex'>
          <div className='flex-1'>
            {Q_eventsDivisions.data && (
              <MultiSelectDropdown
                name='divisions'
                parentForm={form}
                optionsData={
                  targetUserType === 'StudentsAtDivision'
                    ? Q_eventsDivisions.data.filter(
                        (d) => d !== 'Alla sektioner'
                      )
                    : Q_eventsDivisions.data
                }
                label='Sektioner / Divisions'
                description='Om inget är valt väljs Alla sektioner'
              />
            )}
          </div>
          <div className='flex-1' />
        </div>
      </div>
    </>
  );
}
